// =========== ANIM GSAP ===========

gsap.registerPlugin(ScrollTrigger);

const keepAnimations = [
  "navbar",
  "header-description",
  "button-header",
  "h1",
  "header-image",
  "header-image-text",
  "header-logo",
];

function getStartTrigger() {
  return "top 85%";
}

gsap.set("[data-gsap-hidden='true']", { visibility: "visible" });

document.addEventListener("DOMContentLoaded", () => {
  const isMobile = window.innerWidth <= 768;

  ScrollTrigger.config({
    ignoreMobileResize: true,
  });

  const navbar = document.querySelector('[data-anim="navbar"]');
  if (navbar) {
    gsap.set(navbar, { visibility: "visible" });
    gsap.fromTo(
      navbar,
      {
        opacity: 0,
        filter: "blur(8px)",
        y: -20,
      },
      {
        opacity: 1,
        filter: "blur(0px)",
        y: 0,
        duration: isMobile ? 0.8 : 1,
        delay: isMobile ? 1 : 1.8,
        ease: "circ.out",
        onComplete: () => navbar.setAttribute("data-animated", "true"),
      }
    );
  }

  const baseScrollTrigger = {
    start: "top 85%",
    end: "bottom top",
    toggleActions: "play none none none",
    once: true,
  };

  const delayedScrollTrigger = {
    ...baseScrollTrigger,
    start: getStartTrigger(),
  };

  function createAnimation(
    selector,
    properties,
    triggerConfig = baseScrollTrigger
  ) {
    if (isMobile && !keepAnimations.includes(selector)) return;

    const elements = document.querySelectorAll(`[data-anim="${selector}"]`);
    elements.forEach((element) => {
      if (element.hasAttribute("data-animated")) return;

      gsap.from(element, {
        ...properties,
        scrollTrigger: {
          ...triggerConfig,
          trigger: element,
        },
        onComplete: () => element.setAttribute("data-animated", "true"),
      });
    });
  }

  const standardAnimations = {
    "header-description": {
      y: "100%",
      opacity: 0,
      filter: "blur(8px)",
      duration: 0.5,
      stagger: 0.1,
      delay: 1,
      ease: "circ.out",
    },
    "button-header": {
      y: "100%",
      opacity: 0,
      filter: "blur(8px)",
      duration: 0.5,
      stagger: 0.1,
      delay: 1.2,
      ease: "circ.out",
    },
    h1: {
      y: "100%",
      opacity: 0,
      filter: "blur(8px)",
      duration: 0.5,
      stagger: 0.1,
      delay: 0.7,
      ease: "circ.out",
    },
  };

  const delayedAnimations = {
    "element-1": {
      opacity: 0,
      filter: "blur(8px)",
      y: "1rem",
      duration: 0.5,
      delay: 0.0,
      ease: "circ.out",
    },
    "element-2": {
      opacity: 0,
      filter: "blur(8px)",
      y: "3rem",
      duration: 0.5,
      delay: 0.3,
      ease: "circ.out",
    },
    "element-3": {
      opacity: 0,
      filter: "blur(8px)",
      y: "3rem",
      duration: 0.5,
      delay: 0.6,
      ease: "circ.out",
    },
    "element-4": {
      opacity: 0,
      filter: "blur(8px)",
      y: "3rem",
      duration: 0.5,
      delay: 0.9,
      ease: "circ.out",
    },
  };

  if (!isMobile) {
    document
      .querySelectorAll('[data-anim="element-stagger-group"]')
      .forEach((container) => {
        const staggerElements = container.querySelectorAll(
          '[data-anim="element-stagger"]'
        );
        if (staggerElements.length > 0) {
          gsap.from(staggerElements, {
            scrollTrigger: {
              trigger: container,
              ...delayedScrollTrigger,
            },
            opacity: 0,
            filter: "blur(8px)",
            y: "3rem",
            duration: 0.5,
            stagger: 0.3,
            ease: "circ.out",
            onComplete: function () {
              this.targets().forEach((target) =>
                target.setAttribute("data-animated", "true")
              );
            },
          });
        }
      });
  }

  Object.entries(standardAnimations).forEach(([selector, properties]) => {
    createAnimation(selector, properties);
  });

  if (!isMobile) {
    Object.entries(delayedAnimations).forEach(([selector, properties]) => {
      createAnimation(selector, properties, delayedScrollTrigger);
    });
  }

  const headerImages = document.querySelectorAll('[data-anim="header-image"]');
  if (
    headerImages.length > 0 &&
    (!isMobile || keepAnimations.includes("header-image"))
  ) {
    gsap.from(headerImages, {
      scrollTrigger: {
        ...baseScrollTrigger,
        trigger: headerImages[0],
      },
      opacity: 0,
      scale: 1.2,
      duration: 1,
      stagger: 0.3,
      delay: 0.5,
      ease: "circ.out",
      onComplete: function () {
        this.targets().forEach((target) =>
          target.setAttribute("data-animated", "true")
        );
      },
    });
  }

  const headerImageTexts = document.querySelectorAll(
    '[data-anim="header-image-text"]'
  );
  if (!isMobile || keepAnimations.includes("header-image-text")) {
    headerImageTexts.forEach((text) => {
      gsap.fromTo(
        text,
        {
          y: "100%",
          opacity: 0,
          filter: "blur(8px)",
        },
        {
          y: 0,
          opacity: 1,
          filter: "blur(0px)",
          duration: 0.5,
          ease: "circ.out",
        }
      );

      gsap.to(text, {
        opacity: 0,
        duration: 0.5,
        delay: 3,
        ease: "circ.out",
      });
    });
  }

  const allLogos = [
    ...document.querySelectorAll(
      '[data-anim="header-logo"], [data-anim="header-logo-no-filter"]'
    ),
  ];

  if (
    allLogos.length > 0 &&
    (!isMobile || keepAnimations.includes("header-logo"))
  ) {
    allLogos.forEach((logo) => {
      const isNoFilter =
        logo.getAttribute("data-anim") === "header-logo-no-filter";
      gsap.set(logo, {
        opacity: 0,
        filter: isNoFilter
          ? "brightness(1) blur(8px)"
          : "brightness(0) invert(1) blur(8px)",
      });
    });

    gsap.to(allLogos, {
      scrollTrigger: {
        ...baseScrollTrigger,
        trigger: allLogos[0],
      },
      opacity: 1,
      filter: function (index, target) {
        const isNoFilter =
          target.getAttribute("data-anim") === "header-logo-no-filter";
        return isNoFilter
          ? "brightness(1) blur(0px)"
          : "brightness(0) invert(1) blur(0px)";
      },
      duration: 1,
      stagger: 0.08,
      ease: "circ.out",
      onComplete: function () {
        this.targets().forEach((target) =>
          target.setAttribute("data-animated", "true")
        );
      },
    });
  }

  if (!isMobile) {
    const rdvItems = document.querySelectorAll('[data-anim="rdv-item"]');
    const rdvLists = document.querySelectorAll('[data-anim="rdv-list"]');

    if (rdvItems.length > 0) {
      const rdvTimeline = gsap.timeline({
        scrollTrigger: {
          ...baseScrollTrigger,
          trigger: rdvItems[0],
          start: getStartTrigger(),
        },
      });

      rdvTimeline.from(rdvItems, {
        opacity: 0,
        filter: "blur(8px)",
        y: "3rem",
        duration: 0.5,
        delay: 0.3,
        stagger: 0.2,
        ease: "circ.out",
        onComplete: function () {
          this.targets().forEach((target) =>
            target.setAttribute("data-animated", "true")
          );
        },
      });

      rdvTimeline.from(
        rdvLists,
        {
          opacity: 0,
          filter: "blur(8px)",
          y: "3rem",
          duration: 0.5,
          stagger: 0.2,
          ease: "circ.out",
          onComplete: function () {
            this.targets().forEach((target) =>
              target.setAttribute("data-animated", "true")
            );
          },
        },
        ">"
      );
    }
  }

  if (!isMobile) {
    const footerTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: ".footer",
        start: getStartTrigger(),
        once: true,
      },
    });

    const footerDividers = document.querySelectorAll(
      '[data-anim="footer-divider"]'
    );
    const footerItems = document.querySelectorAll('[data-anim="footer-item"]');

    gsap.set([footerDividers, footerItems], {
      opacity: 0,
      filter: "blur(8px)",
    });

    gsap.set(footerItems, {
      y: "3rem",
    });

    if (footerDividers.length > 0) {
      footerTimeline.to(footerDividers, {
        opacity: 1,
        filter: "blur(0px)",
        duration: 0.5,
        stagger: 0.4,
        ease: "circ.out",
        onComplete: function () {
          this.targets().forEach((target) =>
            target.setAttribute("data-animated", "true")
          );
        },
      });
    }

    if (footerItems.length > 0) {
      footerTimeline.to(
        footerItems,
        {
          opacity: 1,
          filter: "blur(0px)",
          y: 0,
          duration: 0.3,
          stagger: 0.2,
          ease: "circ.out",
          onComplete: function () {
            this.targets().forEach((target) =>
              target.setAttribute("data-animated", "true")
            );
          },
        },
        "-=0.2"
      );
    }
  }

  let scrollTimeout;
  if (isMobile) {
    window.addEventListener(
      "scroll",
      () => {
        document.body.classList.add("is-scrolling");
        clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(() => {
          document.body.classList.remove("is-scrolling");
        }, 150);
      },
      { passive: true }
    );
  }
});

// =========== GRAINED ===========

window.grainedOptions = {
  animate: window.innerWidth > 768,
  patternWidth: 125,
  patternHeight: 125,
  grainOpacity: 0.05,
  grainDensity: 1,
  grainWidth: 1.1,
  grainHeight: 1.1,
  grainChaos: 0.5,
  grainSpeed: 10,
};

window.addEventListener("resize", () => {
  const wasAnimated = window.grainedOptions.animate;
  window.grainedOptions.animate = window.innerWidth > 768;

  if (
    wasAnimated !== window.grainedOptions.animate &&
    typeof window.initGrained === "function"
  ) {
    window.initGrained();
  }
});

(function (window, document) {
  "use strict";

  function createGrainedKeyframes() {
    if (document.getElementById("grained-keyframes-base")) {
      return;
    }

    const keyframes = [
      "0%:-10%,10%",
      "10%:-25%,0%",
      "20%:-30%,10%",
      "30%:-30%,30%",
      "40%:-20%,20%",
      "50%:-15%,10%",
      "60%:-20%,20%",
      "70%:-5%,20%",
      "80%:-25%,5%",
      "90%:-30%,25%",
      "100%:-10%,10%",
    ];

    const prefixes = ["", "-moz-", "-o-animation-", "-webkit-", "-ms-"];
    let animationCSS = "";

    prefixes.forEach((prefix) => {
      animationCSS += `@${prefix}keyframes grained {`;
      keyframes.forEach((keyframe) => {
        const [key, value] = keyframe.split(":");
        animationCSS += `${key}{${prefix}transform:translate(${value});}`;
      });
      animationCSS += "}";
    });

    const styleElement = document.createElement("style");
    styleElement.type = "text/css";
    styleElement.id = "grained-keyframes-base";
    styleElement.innerHTML = animationCSS;
    document.head.appendChild(styleElement);
  }

  function generateNoise(options) {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = options.patternWidth;
    canvas.height = options.patternHeight;

    for (let w = 0; w < options.patternWidth; w += options.grainDensity) {
      for (let h = 0; h < options.patternHeight; h += options.grainDensity) {
        const rgb = (Math.random() * 256) | 0;
        ctx.fillStyle = `rgba(${rgb}, ${rgb}, ${rgb}, ${options.grainOpacity})`;
        ctx.fillRect(w, h, options.grainWidth, options.grainHeight);
      }
    }

    return canvas.toDataURL("image/png");
  }

  function initGrained(element) {
    const elementId =
      element.id || `grained-${Math.floor(Math.random() * 1000000)}`;
    if (!element.id) {
      element.id = elementId;
    }

    if (element.style.position !== "absolute") {
      element.style.position = "relative";
    }
    element.style.overflow = "hidden";

    const defaultOptions = {
      animate: true,
      patternWidth: 100,
      patternHeight: 100,
      grainOpacity: 0.1,
      grainDensity: 1,
      grainWidth: 1,
      grainHeight: 1,
      grainChaos: 0.5,
      grainSpeed: 20,
    };

    const options = Object.assign({}, defaultOptions, window.grainedOptions);

    const noise = generateNoise(options);
    const prefixes = ["", "-moz-", "-o-animation-", "-webkit-", "-ms-"];

    let grainedStyles = `background-image: url(${noise});`;
    grainedStyles +=
      'position: absolute;content: "";height: 300%;width: 300%;left: -100%;top: -100%;';

    if (options.animate) {
      prefixes.forEach((prefix) => {
        grainedStyles += `${prefix}animation-name:grained;`;
        grainedStyles += `${prefix}animation-iteration-count: infinite;`;
        grainedStyles += `${prefix}animation-duration: ${options.grainChaos}s;`;
        grainedStyles += `${prefix}animation-timing-function: steps(${options.grainSpeed}, end);`;
      });
    }

    let grainElement = document.getElementById(`grained-style-${elementId}`);
    if (!grainElement) {
      grainElement = document.createElement("style");
      grainElement.type = "text/css";
      grainElement.id = `grained-style-${elementId}`;
      document.head.appendChild(grainElement);
    }
    grainElement.innerHTML = `#${elementId}::before{${grainedStyles}}`;
  }

  function initialize() {
    createGrainedKeyframes();
    document.querySelectorAll("[data-grained]").forEach(initGrained);
  }

  if (document.readyState === "loading") {
    document.addEventListener("DOMContentLoaded", initialize);
  } else {
    initialize();
  }

  window.initGrained = initialize;
})(window, document);

// ===================================
// NAVBAR
// ===================================

const blackSections = document.querySelectorAll("[data-theme='black']");
const whiteSections = document.querySelectorAll("[data-theme='white']");
const navbarLogo = document.querySelector(".navbar_name.w-embed");

navbarLogo.style.color = "#ffffff";

function updateLogoColor() {
  const scrollPosition = window.scrollY + window.innerHeight * 0.05;

  let inBlackSection = false;
  blackSections.forEach((section) => {
    const rect = section.getBoundingClientRect();
    const sectionTop = rect.top + window.scrollY;
    const sectionBottom = sectionTop + rect.height;

    if (scrollPosition >= sectionTop && scrollPosition <= sectionBottom) {
      inBlackSection = true;
    }
  });

  if (!inBlackSection) {
    whiteSections.forEach((section) => {
      const rect = section.getBoundingClientRect();
      const sectionTop = rect.top + window.scrollY;
      const sectionBottom = sectionTop + rect.height;

      if (scrollPosition >= sectionTop && scrollPosition <= sectionBottom) {
        navbarLogo.style.color = "#000000";
      }
    });
  } else {
    navbarLogo.style.color = "#ffffff";
  }
}

window.addEventListener("load", (event) => {
  if (typeof gsap !== "undefined" && typeof ScrollTrigger !== "undefined") {
    gsap.registerPlugin(ScrollTrigger);

    blackSections.forEach((section) => {
      ScrollTrigger.create({
        trigger: section,
        start: "top 5%",
        end: "bottom 5%",
        markers: false,
        onEnter: () => {
          navbarLogo.style.color = "#ffffff";
        },
        onEnterBack: () => {
          navbarLogo.style.color = "#ffffff";
        },
      });
    });

    whiteSections.forEach((section) => {
      ScrollTrigger.create({
        trigger: section,
        start: "top 5%",
        end: "bottom 5%",
        markers: false,
        onEnter: () => {
          navbarLogo.style.color = "#000000";
        },
        onEnterBack: () => {
          navbarLogo.style.color = "#000000";
        },
      });
    });

    setTimeout(updateLogoColor, 50);
  }
});

window.addEventListener("scroll", updateLogoColor);

let isEnterPressed = false;

document.addEventListener("keydown", function (event) {
  if (event.key === "Enter" && !isEnterPressed) {
    isEnterPressed = true;
    const button = document.getElementById("btn-keyboard");

    if (button) {
      button.style.cssText = `
                border-top-left-radius: 0.9rem;
                border-top-right-radius: 0.9rem;
                border-bottom-left-radius: 0.9rem;
                border-bottom-right-radius: 0.9rem;
                box-shadow: 0 4px 4px 0 var(--brand--black-40), inset 8px 8px 7px -5px var(--brand--white), inset -8px -8px 7px -5px var(--brand--black-20);
                transform: translate(0px, 5px);
                transition-property: all;
                transition-duration: 100ms;
                transition-timing-function: cubic-bezier(.55, .055, .675, .19);
            `;

      button.click();

      setTimeout(() => {
        button.style.cssText = "";
      }, 100);
    }
  }
});

document.addEventListener("keyup", function (event) {
  if (event.key === "Enter") {
    isEnterPressed = false;
  }
});

// ===================================
// SECTION • ABOUT
// ===================================

let mainButton = $(".main-button");
let heroButtonWrapper = $(".banner-wf-cp");
let fixedButtonWrapper = $(".fixed-button_wrapper");

function moveButtonInto(element) {
  let state = Flip.getState(mainButton);
  mainButton.appendTo(element);
  Flip.from(state, {
    duration: 0.4,
    ease: "power2.out",
  });
}

ScrollTrigger.create({
  trigger: heroButtonWrapper,
  start: "top center",
  end: "bottom center",
  onLeave: () => moveButtonInto(fixedButtonWrapper),
  onEnterBack: () => moveButtonInto(heroButtonWrapper),
});

// ===================================
// SECTION • TESTIMONIAL
// ===================================

$(document).ready(function () {
  const testimonialMainSlider = new Swiper(".testimonial_slider", {
    grabCursor: true,
    watchSlidesProgress: true,
    loop: true,
    speed: 400,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
      waitForTransition: true,
    },
    keyboard: {
      delay: 5000,
      enabled: true,
      onlyInViewport: true,
    },
    slidesPerView: "auto",
    centeredSlides: true,
    on: {
      beforeInit: function (swiper) {
        swiper.el.style.setProperty(
          "--animation-duration",
          swiper.params.autoplay.delay
        );
      },
      progress(swiper) {
        const scaleStep = 0.2;
        const zIndexMax = swiper.slides.length;
        swiper.slides.map((eachSlideElement) => {
          const slideProgress = eachSlideElement.progress;
          const absProgress = Math.abs(slideProgress);
          let modify = 1;
          if (absProgress > 1) {
            modify = (absProgress - 1) * scaleStep + 1;
          }
          const translate = `${slideProgress * modify * 82}%`;
          const scale = 1 - absProgress * scaleStep;
          const zIndex = zIndexMax - Math.abs(Math.round(slideProgress));
          eachSlideElement.style.transform = `translateX(${translate}) scale(${scale})`;
          eachSlideElement.style.zIndex = zIndex;
          if (absProgress > 1.9) {
            eachSlideElement.style.opacity = 0;
          } else {
            eachSlideElement.style.opacity = 1;
          }
          const opacityEls = eachSlideElement.querySelectorAll(
            ".testimonial_card_content"
          );
          opacityEls.forEach((opacityEl) => {
            opacityEl.style.opacity = 1 - absProgress;
          });
        });
      },
      setTransition(swiper, duration) {
        swiper.slides.map((eachSlideElement) => {
          const opacityEls = eachSlideElement.querySelectorAll(
            ".testimonial_card_content"
          );
          eachSlideElement.style.transitionDuration = `${duration}ms`;
          opacityEls.forEach((opacityEl) => {
            opacityEl.style.transitionDuration = `${duration}ms`;
          });
        });
      },
    },
  });

  const testimonialLogoSlider = new Swiper(".testimonial_slider_logo", {
    loop: true,
    slidesPerView: "auto",
    centeredSlides: true,
    watchSlidesProgress: true,
    slideToClickedSlide: true,
  });

  testimonialMainSlider.controller.control = testimonialLogoSlider;
  testimonialLogoSlider.controller.control = testimonialMainSlider;
});

// ===================================
// SECTION • FAQS
// ===================================

function scrollToFAQElement(element, offset = "7rem") {
  const offsetInPx =
    parseFloat(getComputedStyle(document.documentElement).fontSize) *
    parseFloat(offset);
  const elementPosition = element.getBoundingClientRect().top + window.scrollY;
  const offsetPosition = elementPosition - offsetInPx;

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  });
}

function initFAQEvents() {
  $('[data-click="faq"]')
    .off("click")
    .click(function () {
      const $this = $(this);
      const $faqCard = $this.closest(".faq_card_wrap");
      const $content = $faqCard.find(".faq_card_content");

      if (!$this.is(".open")) {
        $('[data-click="faq"].open').each((i, item) => {
          item.click();
        });

        $this.addClass("open");

        const duration =
          parseFloat(window.getComputedStyle($content[0]).transitionDuration) *
          1000;

        setTimeout(() => {
          const $summary = $faqCard.find(".faq_card_toggle");
          if ($summary.length) {
            scrollToFAQElement($summary[0], "7rem");
          }
        }, duration);
      } else {
        $this.removeClass("open");

        const duration =
          parseFloat(window.getComputedStyle($content[0]).transitionDuration) *
          1000;

        setTimeout(() => {
          const $summary = $faqCard.find(".faq_card_toggle");
          if ($summary.length) {
            scrollToFAQElement($summary[0], "7rem");
          }
        }, duration);
      }
    });

  $('a[href^="#"]').click(function (e) {
    e.preventDefault();
    const targetId = $(this).attr("href");
    const $target = $(targetId);

    if ($target.length) {
      const $faqCheckbox = $target
        .closest(".faq_card_wrap")
        .find('[data-click="faq"]');
      if ($faqCheckbox.length && !$faqCheckbox.is(".open")) {
        $faqCheckbox.trigger("click");

        const $content = $target
          .closest(".faq_card_wrap")
          .find(".faq_card_content");
        const duration =
          parseFloat(window.getComputedStyle($content[0]).transitionDuration) *
          1000;

        setTimeout(() => {
          scrollToFAQElement($target[0], "7rem");
        }, duration);
      } else {
        scrollToFAQElement($target[0], "7rem");
      }
    }
  });
}

initFAQEvents();

const observer = new MutationObserver((mutations) => {
  mutations.forEach((mutation) => {
    if (mutation.addedNodes.length) {
      const hasFAQElements = Array.from(mutation.addedNodes).some(
        (node) =>
          node.nodeType === 1 &&
          (node.hasAttribute("data-click") ||
            node.querySelector('[data-click="faq"]'))
      );

      if (hasFAQElements) {
        initFAQEvents();
      }
    }
  });
});

observer.observe(document.body, {
  childList: true,
  subtree: true,
});

window.addEventListener("load", () => {
  if (window.location.hash) {
    const targetId = window.location.hash;
    const $target = $(targetId);

    if ($target.length) {
      setTimeout(() => {
        const $faqCheckbox = $target
          .closest(".faq_card_wrap")
          .find('[data-click="faq"]');
        if ($faqCheckbox.length && !$faqCheckbox.is(".open")) {
          $faqCheckbox.trigger("click");

          const $content = $target
            .closest(".faq_card_wrap")
            .find(".faq_card_content");
          const duration =
            parseFloat(
              window.getComputedStyle($content[0]).transitionDuration
            ) * 1000;

          setTimeout(() => {
            scrollToFAQElement($target[0], "7rem");
          }, duration);
        } else {
          scrollToFAQElement($target[0], "7rem");
        }
      }, 100);
    }
  }
});

// ===================================
// SECTION • RDV
// ===================================

document.addEventListener("DOMContentLoaded", function () {
  const moisEnFrancais = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];

  const moisActuel = new Date().getMonth();

  const elementsMonth = document.getElementsByClassName("month");

  Array.from(elementsMonth).forEach((element) => {
    element.textContent = moisEnFrancais[moisActuel];
  });
});

let calLoaded = false;

function loadCalCom() {
  if (calLoaded) return;
  calLoaded = true;

  const calScript = document.createElement("script");
  calScript.text = `
    (function (C, A, L) { 
      let p = function (a, ar) { a.q.push(ar); }; 
      let d = C.document; 
      C.Cal = C.Cal || function () { 
        let cal = C.Cal; 
        let ar = arguments; 
        if (!cal.loaded) { 
          cal.ns = {}; 
          cal.q = cal.q || []; 
          d.head.appendChild(d.createElement("script")).src = A; 
          cal.loaded = true; 
        } 
        if (ar[0] === L) { 
          const api = function () { p(api, arguments); }; 
          const namespace = ar[1]; 
          api.q = api.q || []; 
          if(typeof namespace === "string"){
            cal.ns[namespace] = cal.ns[namespace] || api;
            p(cal.ns[namespace], ar);
            p(cal, ["initNamespace", namespace]);
          } else p(cal, ar); 
          return;
        } 
        p(cal, ar); 
      }; 
    })(window, "https://app.cal.com/embed/embed.js", "init");
    Cal("init", "intro", {origin:"https://cal.com"});
    Cal.ns["intro"]("ui", {
      "styles":{"branding":{"brandColor":"#ff4200"}},
      "hideEventTypeDetails":false,
      "layout":"month_view"
    });
  `;
  document.body.appendChild(calScript);
}

document.addEventListener("DOMContentLoaded", () => {
  const bookingButtons = document.querySelectorAll("[data-booking]");
  bookingButtons.forEach((button) => {
    button.addEventListener("mouseenter", loadCalCom, { once: true });
    button.addEventListener("touchstart", loadCalCom, { once: true });
  });
});

document.addEventListener("DOMContentLoaded", () => {
  const counters = document.querySelectorAll(".rdv_note_counter");

  const animateCounter = (element) => {
    const start = parseFloat(element.getAttribute("numbercount-start") || 0);
    const end = parseFloat(element.getAttribute("numbercount-end"));
    const duration = parseFloat(
      element.getAttribute("numbercount-duration") || 1000
    );

    const isEndValueInteger = Number.isInteger(end);

    const formatNumber = (num) => {
      if (isEndValueInteger) {
        return Math.round(num);
      }
      return parseFloat(num.toFixed(1)).toString();
    };

    const increment = isEndValueInteger ? 1 : 0.1;
    const steps = Math.ceil((end - start) / increment);
    const timePerStep = duration / steps;

    let currentStep = 0;

    const animate = () => {
      if (currentStep <= steps) {
        const currentValue = Math.min(start + currentStep * increment, end);
        element.textContent = formatNumber(currentValue);

        currentStep++;

        if (currentValue < end) {
          setTimeout(animate, timePerStep);
        } else {
          element.textContent = formatNumber(end);
        }
      }
    };

    animate();
  };

  const options = {
    threshold: 0.1,
  };

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        animateCounter(entry.target);
        observer.unobserve(entry.target);
      }
    });
  }, options);

  counters.forEach((counter) => observer.observe(counter));
});

/// Hide invisible element
const elementsToRemove = document.querySelectorAll(
  ".w-condition-invisible, .w-dyn-empty, .w-dyn-hide, .w-dyn-bind-empty"
);
elementsToRemove.forEach((element) => {
  element.remove();
});

/// Charger les scripts au bon moment
(() => {
  const scriptsConfig = {
    cmsslider: {
      url: "https://cdn.jsdelivr.net/npm/@finsweet/attributes-cmsslider@1/cmsslider.js",
      selector: ".section_projets",
    },
    cmsload: {
      url: "https://cdn.jsdelivr.net/npm/@finsweet/attributes-cmsload@1/cmsload.js",
      selector: ".section_faq",
    },
    modal: {
      url: "https://cdn.jsdelivr.net/npm/@finsweet/attributes-modal@1/modal.js",
      selector: ".footer",
    },
  };

  const loadScript = (url) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = url;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  };

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const scriptId = Object.entries(scriptsConfig).find(([_, config]) =>
            entry.target.matches(config.selector)
          )?.[0];
          if (scriptId) {
            loadScript(scriptsConfig[scriptId].url);
            observer.unobserve(entry.target);
          }
        }
      });
    },
    { threshold: 0.1 }
  );

  document.addEventListener("DOMContentLoaded", () => {
    Object.values(scriptsConfig).forEach((config) => {
      const element = document.querySelector(config.selector);
      if (element) {
        observer.observe(element);
      }
    });
  });
})();